import React from "react";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useDataProvider,
  useRefresh,
} from "react-admin";
import { useParams } from "react-router-dom";
import { errorHandlingFactory, getErrorMessage } from "../../utils/errors";
import dayjs from "dayjs";
import { TimePickerCustom } from "../custom_fields/customTimeInput";
import { Button } from "@mui/material";

export default function LocationEdit() {
  const notify = useNotify();
  const { id } = useParams();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const onError = errorHandlingFactory(notify);

  const transform = (data, { previousData }) => {
    if (data.pickUpTime !== previousData.pickUpTime) {
      data.pickUpTime = dayjs(data.pickUpTime).format("HH:mm");
    }
    return data;
  };

  const handleSyncData = (e) => {
    e.preventDefault();
    dataProvider
      .syncLocation(id)
      .then(() => {
        notify("location data successfully sync", { type: "info" });
        refresh();
      })
      .catch((err) => {
        const message = getErrorMessage(err);
        notify(`error syncing location data, ${message}`, { type: "error" });
      });
  };

  return (
    <Edit mutationOptions={{ onError }} transform={transform}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput disabled source="name" />
        <TextInput disabled source="address1" />
        <TextInput disabled source="address2" />
        <TextInput disabled source="lat" />
        <TextInput disabled source="lon" />
        <ReferenceInput source="centerId" reference="centers">
          <SelectInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="timezone" reference="timezones">
          <SelectInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <TextInput source="code7l" />
        <TimePickerCustom source="pickUpTime" />
        <TextInput source="specificLocation" multiline fullWidth />
        <TextInput source="internalNotes" multiline fullWidth />
        <Button onClick={handleSyncData}>Sync Location Data</Button>
      </SimpleForm>
    </Edit>
  );
}
