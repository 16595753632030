export const getErrorMessage = (error) => {
  let detail = error.body && error.body.detail ? error.body.detail : null;
  if (typeof detail == "object" && Array.isArray(detail)) {
    const obj_error = detail[0];
    detail = obj_error.msg ? obj_error.msg : null;
  }
  return `${error.message} \n ${detail}`;
};

export const errorDisplayConfig = {
  autoHideDuration: 5000,
  multiLine: true,
  type: "error",
};

export const errorHandlingFactory = (notifier) => {
  const onError = (error) => {
    notifier(getErrorMessage(error), errorDisplayConfig);
  };
  return onError;
};
