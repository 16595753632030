import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  NumberField,
  ShowButton,
} from "react-admin";
import DateTimeWithLocal from "../custom_fields/dateTimeWithLocal";

const packageReportFilters = [
  <ReferenceInput source="towerId" reference="towers" alwaysOn key={1}>
    <AutocompleteInput optionText="id" optionValue="id" />
  </ReferenceInput>,
];

const defaultSort = { field: "id", order: "DESC" };

export default function PackageReportList() {
  return (
    <List filters={packageReportFilters} sort={defaultSort} perPage={50}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="towerId" reference="towers">
          <TextField source="name" />
        </ReferenceField>
        <DateTimeWithLocal source="fromDt" />
        <DateTimeWithLocal source="toDt" />
        <NumberField source="lockerDropoffCount" />
        <NumberField source="letterDropoffCount" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}
