import React from "react";
import { Box, Typography } from "@mui/material";
import { useListContext } from "react-admin";
import { TagsInput } from "./TagsInput";

const TowerModelFilter = () => {
  const { filterValues, setFilters } = useListContext();

  const handleTagsChange = (newValues) => {
    setFilters({ ...filterValues, tower__model_name: newValues }, null, false); // false to not replace current filters
  };

  return (
    <Box>
      <TagsInput
        value={filterValues.tower__model_name || []}
        onChange={handleTagsChange}
        name="Models"
        placeHolder="enter a tower model"
      />
      <Typography variant="caption" style={{ fontStyle: "italic" }}>
        press enter to add new
      </Typography>
    </Box>
  );
};

export default TowerModelFilter;
