import { orange } from "@mui/material/colors";

const colors = {
  white: "white",
  yellowLight: "#DFE961",
  yellowGreyDark: "#20201D",
  orange: orange[400],
  redBright: "#ff4444",
  redDark: "#2e1415",
};

export default colors;
