import React from "react";
import { useRecordContext } from "react-admin";
import PropTypes from "prop-types";

export const CustomTimeField = ({ source }) => {
  const record = useRecordContext();
  const time = record[source];

  if (time) {
    const [hours, minutes] = time.split(":");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return <span>{`${formattedHours}:${minutes} ${ampm}`}</span>;
  } else {
    return null;
  }
};

CustomTimeField.propTypes = {
  source: PropTypes.string,
};
