import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  ReferenceField,
  BooleanInput,
  SelectInput,
} from "react-admin";
import MarkunreadIcon from "@mui/icons-material/MarkAsUnread";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { PostPagination } from "../packages/PackagesList";
import ColoredTextField from "../custom_fields/coloredTextFIeld";
import DaysSinceField from "../custom_fields/daysSince";
import { theme } from "../../theme";
import ColoredSelectFilter from "../custom_fields/coloredSelectFilter";
import AccessModeHeader from "../custom_fields/AccessModeTooltip";

const lockerFilters = [
  <BooleanInput label="Is low locker" source="is_low_locker" key={1} />,
  <ColoredSelectFilter
    source="status"
    choices={[
      { id: "available", name: "Available" },
      {
        id: "in_use",
        name: "In Use",
        displayColor: theme.palette.specials.inUse,
      },
      {
        id: "out_of_service",
        name: "Out of Service",
        displayColor: theme.palette.specials.outOfService,
      },
    ]}
    label="Status"
    alwaysOn
    key={2}
  />,
  <SelectInput
    source="type"
    choices={[
      { id: "medium", name: "Medium" },
      { id: "large", name: "Large" },
      { id: "extra_large", name: "Extra Large" },
      { id: "letter_dropoff", name: "Letter Dropoff" },
      { id: "letter_pickup", name: "Letter Pickup" },
    ]}
    alwaysOn
    key={3}
  />,
];

export default function LockersList(props) {
  return (
    <List
      {...props}
      filters={lockerFilters}
      perPage={50}
      pagination={<PostPagination />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <TextField source="type" sortable={false} />
        <ColoredTextField
          source="status"
          sortable={false}
          valueColor={{
            in_use: theme.palette.specials.inUse,
            out_of_service: theme.palette.specials.outOfService,
          }}
          valueIcon={{
            in_use: <MarkunreadIcon />,
            out_of_service: <ReportProblemOutlinedIcon />,
          }}
        />
        <ReferenceField source="towerId" reference="towers">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="isLowLocker" sortable={false} />
        <TextField
          source="accessMode"
          label={<AccessModeHeader />}
          sortable={false}
        />
        <ReferenceField source="packageId" reference="packages">
          <TextField source="id" />
        </ReferenceField>
        <DaysSinceField source="lastDropoff" />
        <EditButton></EditButton>
      </Datagrid>
    </List>
  );
}
