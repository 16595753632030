import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const exporterFactory = (senderId) => {
  const userTransactionsExporter = (
    records,
    fetchRelatedRecords,
    dataProvider
  ) => {
    fetchRelatedRecords(records, "towerId", "towers")
      .then((packs) => {
        const dataWithTowers = records.map((record) => {
          // eslint-disable-next-line no-unused-vars
          const { statusId, pickedupBy, ...recordToExport } = record;
          return {
            ...recordToExport,
            towerName: packs[record.towerId].name,
          };
        });
        return dataProvider
          .getOne("users", { id: senderId })
          .then(({ data }) => {
            return dataWithTowers.map((record) => {
              return {
                ...record,
                sender: data.email,
              };
            });
          });
      })
      .then((dataExpanded) => {
        jsonExport(
          dataExpanded,
          {
            headers: [
              "id",
              "sender",
              "status",
              "towerId",
              "towerName",
              "lockerId",
              "lockerName",
              "lockerType",
              "city",
              "state",
              "dropoffTime",
              "pickedupAt",
              "npsScore",
            ],
          },
          (err, csv) => {
            downloadCSV(csv, "user_transactions");
          }
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return userTransactionsExporter;
};

export default exporterFactory;
