import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";

const userFeedbackExporter = async (
  records,
  fetchRelatedRecords,
  dataProvider
) => {
  fetchRelatedRecords(records, "packageId", "packages").then(
    async (packages) => {
      const towerIds = Object.values(packages).map((pkg) => pkg.towerId);
      const { data: towers } = await dataProvider.getMany("towers", {
        ids: towerIds,
      });

      const data = records.map((record) => {
        const packageRecord = packages[record.packageId];
        const tower = towers.find((t) => t.id === packageRecord.towerId);
        return {
          ...record,
          towerId: tower?.id,
          towerName: tower?.name,
          towerModel: tower?.modelName,
        };
      });

      return jsonExport(
        data,
        {
          headers: [
            "id",
            "packageId",
            "dropoff_time",
            "type",
            "score",
            "feedback",
            "userId",
            "towerName",
            "towerId",
            "towerModel",
            "userIsTester",
            "totalDropoffs",
          ],
        },
        (err, csv) => {
          downloadCSV(csv, "user_feedback");
        }
      );
    }
  );
};

export default userFeedbackExporter;
