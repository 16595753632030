import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
} from "react-admin";

export default function UserFeedbackEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id"></TextInput>
        <TextInput disabled source="type" />
        <NumberInput source="score" />
        <TextInput multiline source="feedback" />
        <ReferenceInput
          source="userId"
          reference="users"
          filterToQuery={(searchText) => ({ email: searchText })}
          allowEmpty
        >
          <AutocompleteInput optionText="email" optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
