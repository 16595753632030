import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  ReferenceField,
  BooleanField,
} from "react-admin";

import DaysSinceField from "../custom_fields/daysSince";
import ActivateSmartPickupButton from "./buttons";

export default function TowerShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="modelName" />
        <TextField source="imsiId" />
        <BooleanField source="reported" />
        <ReferenceField source="locationId" reference="locations">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <NumberField source="successfulDropoffs" sortable={false} />
        <NumberField source="successfulPackagesDropoffs" sortable={false} />
        <NumberField
          source="totalDropoffsPending"
          sortable={false}
          label="Dropoffs Pending"
        />
        <DaysSinceField source="lastDropoff" />
        <ActivateSmartPickupButton />
      </SimpleShowLayout>
    </Show>
  );
}
