import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Show, SimpleShowLayout, TextField, BooleanField } from "react-admin";
import { Box } from "@mui/material";
import UserTransactionsList from "./UserTransactions";

const UserShowWrapper = (props) => {
  const { id } = useParams();
  return (
    <Box>
      {props.children}
      <h2> User Transactions</h2>
      <UserTransactionsList userId={Number(id)} />
    </Box>
  );
};

UserShowWrapper.propTypes = {
  children: PropTypes.node,
};

export default function UserShow() {
  return (
    <Show component={UserShowWrapper}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <BooleanField source="emailValidated" />
        <BooleanField source="isActive" />
        <BooleanField source="isTester" />
        <TextField source="role" />
      </SimpleShowLayout>
    </Show>
  );
}
