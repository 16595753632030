import * as React from "react";
import { useListContext } from "react-admin";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const getCleanedFilter = (filter, keysToExclude) => {
  Object.keys(filter)
    .filter((key) => !keysToExclude.includes(key))
    .reduce((obj, key) => {
      obj[key] = filter[key];
      return obj;
    }, {});
};

const DateRangeFilter = ({ source, label }) => {
  const [range, setRange] = React.useState("");
  const [from_date, setFromDate] = React.useState(null);
  const [to_date, setToDate] = React.useState(null);

  const graterThan = `${source}_gt`;
  const lowerThan = `${source}_lt`;
  const keysExcludeRange = [graterThan, lowerThan];

  const { filterValues, setFilters } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });

  const cleanedRangeValues = getCleanedFilter(filterValues, keysExcludeRange);

  const handelRangeSelection = (event) => {
    const value = event.target.value;
    cleanDatePickers();
    setRange(value);
    const now = dayjs();

    switch (value) {
      case "today": {
        const startOfDay = now.startOf("day");
        setFilters({
          ...cleanedRangeValues,
          [graterThan]: startOfDay.toISOString(),
        });
        break;
      }
      case "yesterday": {
        const yesterday = now.subtract(1, "day");
        const startOfYesterday = yesterday.startOf("day");
        const endOfYesterday = yesterday.endOf("day");
        setFilters({
          ...cleanedRangeValues,
          [graterThan]: startOfYesterday.toISOString(),
          [lowerThan]: endOfYesterday.toISOString(),
        });
        break;
      }
      case "thisWeek": {
        const startOfWeek = now.startOf("week");
        setFilters({
          ...cleanedRangeValues,
          [graterThan]: startOfWeek.toISOString(),
        });
        break;
      }
      case "lastWeek": {
        const lastWeekStart = now.subtract(1, "week").startOf("week");
        const lastWeekEnd = now.subtract(1, "week").endOf("week");
        setFilters({
          ...cleanedRangeValues,
          [graterThan]: lastWeekStart.toISOString(),
          [lowerThan]: lastWeekEnd.toISOString(),
        });
        break;
      }
      case "thisMonth": {
        const startOfMonth = now.startOf("month");
        setFilters({
          ...cleanedRangeValues,
          [graterThan]: startOfMonth.toISOString(),
        });
        break;
      }
      case "lastMonth": {
        const lastMonthStart = now.subtract(1, "month").startOf("month");
        const lastMonthEnd = now.subtract(1, "month").endOf("month");
        setFilters({
          ...cleanedRangeValues,
          [graterThan]: lastMonthStart.toISOString(),
          [lowerThan]: lastMonthEnd.toISOString(),
        });
        break;
      }
      case "thisYear": {
        const lastMonthStart = now.startOf("year");
        setFilters({
          ...cleanedRangeValues,
          [graterThan]: lastMonthStart.toISOString(),
        });
        break;
      }
      case "lastYear": {
        const lastMonthStart = now.subtract(1, "year").startOf("year");
        const lastYearEnd = now.subtract(1, "year").endOf("year");
        setFilters({
          ...cleanedRangeValues,
          [graterThan]: lastMonthStart.toISOString(),
          [lowerThan]: lastYearEnd.toISOString(),
        });
        break;
      }
      default: {
        setFilters({ ...cleanedRangeValues });
        cleanDatePickers();
      }
    }
  };

  const cleanDatePickers = () => {
    setFromDate(null);
    setToDate(null);
  };

  const handelFromChange = (newValue) => {
    const startTime = newValue.startOf("day").toISOString();
    setFromDate(newValue);
    setFilters({ ...filterValues, [graterThan]: startTime });
  };

  const handelToChange = (newValue) => {
    const endTime = newValue.endOf("day").toISOString();
    setToDate(newValue);
    setFilters({ ...filterValues, [lowerThan]: endTime });
  };

  const resetFilters = () => {
    setRange("");
    cleanDatePickers();
    setFilters({ ...cleanedRangeValues });
  };

  return (
    <FormProvider {...form}>
      <Box display="flex" alignItems="flex-end" mb={1} sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          <InputLabel id="date-range-selector-label">{label}</InputLabel>
          <Select
            labelId="date-range-selector-label"
            id="date-range-selector"
            value={range}
            label={label}
            onChange={handelRangeSelection}
          >
            <MenuItem value={""} style={{ height: 30 }}>
              {" "}
            </MenuItem>
            <MenuItem value={"today"}>Today</MenuItem>
            <MenuItem value={"yesterday"}>Yesterday</MenuItem>
            <MenuItem value={"thisWeek"}>This week</MenuItem>
            <MenuItem value={"lastWeek"}>Last week</MenuItem>
            <MenuItem value={"thisMonth"}>This month</MenuItem>
            <MenuItem value={"lastMonth"}>Last month</MenuItem>
            <MenuItem value={"thisYear"}>This year</MenuItem>
            <MenuItem value={"lastYear"}>Last year</MenuItem>
          </Select>
          <br />
          <Button variant="outlined" onClick={resetFilters}>
            Clean
          </Button>
        </FormControl>
      </Box>
      &nbsp;
      <Box display="flex" alignItems="flex-end" mb={1} sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={from_date}
              onChange={handelFromChange}
              label="from"
            />
            <DatePicker value={to_date} onChange={handelToChange} label="to" />
          </LocalizationProvider>
        </FormControl>
      </Box>
    </FormProvider>
  );
};

DateRangeFilter.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
};

export default DateRangeFilter;
