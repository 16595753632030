import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  useNotify,
} from "react-admin";
import { errorHandlingFactory } from "../../utils/errors";

export default function LockerEdit() {
  const notify = useNotify();
  const onError = errorHandlingFactory(notify);

  return (
    <Edit mutationOptions={{ onError }}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id"></TextInput>
        <TextInput disabled source="name" />
        <BooleanInput disabled source="isLowLocker" />
        <TextInput disabled source="type" />
        <SelectInput
          source="status"
          choices={[
            { id: "available", name: "Available" },
            { id: "in_use", name: "In Use" },
            { id: "out_of_service", name: "Out of Service" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
}
