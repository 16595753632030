import * as React from "react";
import { useListContext } from "react-admin";
import PropTypes from "prop-types";

import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { getCleanedFilter } from "./utils";

const ColoredSelectFilter = ({ source, label, choices }) => {
  const [selection, setSelection] = React.useState("");
  const { filterValues, setFilters } = useListContext();

  const cleanedFilterValues = getCleanedFilter(filterValues, [source]);

  const handelSelection = (event) => {
    const value = event.target.value;
    setSelection(value);
    setFilters({ ...cleanedFilterValues, [source]: value });
  };

  return (
    <FormControl fullWidth sx={{ minWidth: 200 }}>
      <InputLabel id="colored-select-filter-label">{label}</InputLabel>
      <Select
        labelId="colored-select-filter-label"
        id="colored-select-filter"
        value={selection}
        label={label}
        onChange={handelSelection}
      >
        <MenuItem value={""} style={{ height: 30 }} key={0}>
          {" "}
        </MenuItem>
        {choices.map((choice, index) => {
          let sx = {};
          if (Object.prototype.hasOwnProperty.call(choice, "displayColor")) {
            sx["color"] = choice["displayColor"];
          }
          return (
            <MenuItem value={choice.id} key={index + 1} sx={sx}>
              {choice.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

ColoredSelectFilter.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
  choices: PropTypes.array,
};

export default ColoredSelectFilter;
