import { defaultTheme } from "react-admin";
import colors from "./colors";

export const theme = {
  ...defaultTheme,
  palette: {
    mode: "dark",
    primary: {
      main: colors.yellowLight,
    },
    secondary: {
      main: colors.yellowGreyDark,
    },
    specials: {
      inUse: colors.orange,
      outOfService: colors.redBright,
      highlighRow: colors.redDark,
    },
  },
};

export const boxModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: colors.white,
};
