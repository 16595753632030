import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  ReferenceField,
  TextInput,
} from "react-admin";
import { CustomTimeField } from "../custom_fields/customTimeField";
import InfoModalField from "../custom_fields/infoModalField";
import InternalNotesModalField from "../custom_fields/internalNotesModalField";

const locationFilters = [<TextInput source="code_7l" alwaysOn key={1} />];

export default function LocationList(props) {
  return (
    <List filters={locationFilters} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <NumberField source="lat" />
        <NumberField source="lon" />
        <TextField source="address1" />
        <TextField source="address2" />
        <TextField source="city" />
        <TextField source="country" />
        <TextField source="zipCode" />
        <ReferenceField source="centerId" reference="centers">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="code7l" />
        <CustomTimeField source="pickUpTime" />
        <TextField source="timezone" />
        <InfoModalField source="specificLocation" />
        <InternalNotesModalField source="internalNotes" />
        <EditButton></EditButton>
      </Datagrid>
    </List>
  );
}
