import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SelectInput,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  FilterList,
  FilterListItem,
  NumberField,
  Pagination,
  BooleanField,
} from "react-admin";
import { Card, CardContent } from "@mui/material";
import WarningAmber from "@mui/icons-material/WarningAmber";
import PeopleOutline from "@mui/icons-material/PeopleOutline";

import packageTransactionsExporter from "./exporter";
import DateRangeFilter from "../custom_fields/datetimeFilter";
import DaysSinceField from "../custom_fields/daysSince";
import DateTimeWithLocal from "../custom_fields/dateTimeWithLocal";
import { theme } from "../../theme";

export const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const packageFilters = [
  <TextInput label="Sender email" source="sender__email" alwaysOn key={1} />,
  <TextInput label="Courier email" source="courier__email" alwaysOn key={2} />,
  <TextInput label="Tower id" source="locker__tower_id" alwaysOn key={3} />,
  <TextInput
    label="Tower name"
    source="locker__tower__name"
    alwaysOn
    key={4}
  />,
  <SelectInput
    source="status_id"
    choices={[
      { id: 1, name: "In Locker" },
      { id: 2, name: "Picked Up" },
      { id: 3, name: "Dropoff Pending" },
      { id: 4, name: "Dropoff Canceled" },
    ]}
    alwaysOn
    key={5}
  />,
  <TextInput label="Locker type" source="locker__type" key={6} />,
  <TextInput label="Locker name" source="locker__name" key={7} />,
  <DateRangeFilter
    label="Dropoff Range"
    source={"locker_allocation__created_at"}
    key={8}
  />,
  <DateRangeFilter label="Pickup Range" source={"pickedup_at"} key={9} />,
  <ReferenceInput source="location__id" reference="locations" key={10}>
    <AutocompleteInput
      filterToQuery={(searchText) => ({ name: `%${searchText}` })}
      optionText="name"
      optionValue="id"
    />
  </ReferenceInput>,
];

export const PackagesFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
    <CardContent>
      <FilterList label="Possible problems" icon={<WarningAmber />}>
        <FilterListItem label="Yes" value={{ possible_problems: true }} />
        <FilterListItem label="No" value={{ possible_problems: false }} />
      </FilterList>
      <FilterList label="Exclude testers" icon={<PeopleOutline />}>
        <FilterListItem label="Yes" value={{ sender__is_tester: false }} />
      </FilterList>
    </CardContent>
  </Card>
);

export default function PackagesList() {
  const packageRowStyle = (record) => ({
    backgroundColor: record.senderIsTester
      ? theme.palette.specials.highlighRow
      : theme.backgroundColor,
  });

  return (
    <List
      filters={packageFilters}
      aside={<PackagesFilterSidebar />}
      perPage={50}
      pagination={<PostPagination />}
      sort={{ field: "dropoffTime", order: "DESC" }}
      exporter={packageTransactionsExporter}
    >
      <Datagrid rowStyle={packageRowStyle}>
        <TextField source="id" />
        <TextField source="status" sortable={false} />
        <ReferenceField reference="towers" source="towerId" sortable={false}>
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField
          reference="towers"
          source="towerId"
          sortable={false}
          label="Tower Name"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="lockerType" sortable={false} />
        <ReferenceField reference="lockers" source="lockerId" sortable={false}>
          <TextField source="id" label="Locker id" />
        </ReferenceField>
        <TextField source="lockerName" sortable={false} />
        <ReferenceField reference="users" source="senderId">
          <TextField source="email" />
        </ReferenceField>
        <BooleanField source="senderIsTester" />
        <TextField source="city" />
        <TextField source="state" />
        <DaysSinceField source="dropoffTime" showTime />
        <DateTimeWithLocal
          source="dropoffTime"
          label="Tower local dropoff time"
        />
        <ReferenceField source="pickedupBy" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <DaysSinceField source="pickedupAt" showTime />
        <NumberField source="npsScore" />
        <EditButton></EditButton>
      </Datagrid>
    </List>
  );
}
