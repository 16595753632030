import React from "react";
import { Create, SimpleForm, SelectInput, BooleanInput } from "react-admin";
import { JsonInput } from "react-admin-json-view";
import { theme } from "../../theme";

export default function AppSettingsCreate() {
  return (
    <Create>
      <SimpleForm>
        <SelectInput
          source="type"
          choices={[
            { id: "logs", name: "Logs" },
            { id: "version", name: "Version" },
          ]}
        />
        <SelectInput
          source="app"
          choices={[
            { id: "customer", name: "Customer" },
            { id: "driver", name: "Driver" },
          ]}
        />
        <BooleanInput source="default" />
        <JsonInput
          source="settings"
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: false,
            enableClipboard: false,
            displayDataTypes: false,
            theme: "google",
            style: { backgroundColor: theme.palette.secondary.main },
          }}
        />
      </SimpleForm>
    </Create>
  );
}
