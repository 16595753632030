import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  NumberField,
  NumberInput,
  TextInput,
  BooleanField,
  FilterList,
  FilterListItem,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  DateField,
} from "react-admin";
import LinkToRelatedField from "../custom_fields/linkToRelated";
import userFeedbackExporter from "./exporter";
import { theme } from "../../theme";
import { Card, CardContent } from "@mui/material";
import PeopleOutline from "@mui/icons-material/PeopleOutline";
import DateRangeFilter from "../custom_fields/datetimeFilter";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import TowerModelFilter from "../custom_fields/TowerModelFilterField";

const userFeedbackFilters = [
  <TextInput label="Email" source="user__email" alwaysOn key={1} />,
  <NumberInput source="score_lt" label="Lower than" alwaysOn key={2} />,
  <NumberInput source="score_gt" label="Grater than" alwaysOn key={3} />,
  <DateRangeFilter
    label="Dropoff Range"
    source={"package__created_at"}
    key={4}
  />,
  <ReferenceArrayInput
    label="Tower id"
    source="tower__name"
    reference="towers"
    alwaysOn
    key={5}
  >
    <AutocompleteArrayInput
      optionValue="name"
      optionText="name"
      label="Tower name"
      filterToQuery={(searchText) => ({ name: `%${searchText}` })}
    />
  </ReferenceArrayInput>,
];

const defaultSort = { field: "id", order: "DESC" };

export const FeedbackFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, mt: 9, width: 250 }}>
    <CardContent>
      <FilterList label="Exclude testers" icon={<PeopleOutline />}>
        <FilterListItem label="Yes" value={{ user__is_tester: false }} />
      </FilterList>
      <FilterList
        label="Filter by tower model"
        icon={<PrecisionManufacturingIcon />}
      >
        <TowerModelFilter />
      </FilterList>
    </CardContent>
  </Card>
);

export default function UserFeedbackList() {
  const userFeedbackRowStyle = (record) => ({
    backgroundColor: record.userIsTester
      ? theme.palette.specials.highlighRow
      : theme.backgroundColor,
  });

  return (
    <List
      filters={userFeedbackFilters}
      perPage={25}
      sort={defaultSort}
      aside={<FeedbackFilterSidebar />}
      exporter={userFeedbackExporter}
    >
      <Datagrid rowStyle={userFeedbackRowStyle}>
        <TextField source="id" />
        <DateField source="dropoffTime" label="Dropoff Date" />
        <TextField source="type" sortable={false} />
        <NumberField source="score" />
        <TextField source="feedback" sortable={false} />
        <ReferenceField label="User" source="userId" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          label="Tower Id"
          source="packageId"
          reference="packages"
          link={false}
        >
          <TextField source="towerId" />
        </ReferenceField>
        <ReferenceField
          label="Tower Name"
          source="packageId"
          reference="packages"
          link={false}
        >
          <ReferenceField
            source="towerId"
            reference="towers"
            label="Tower Name"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          label="Tower Model"
          source="packageId"
          reference="packages"
          link={false}
        >
          <ReferenceField
            source="towerId"
            reference="towers"
            label="Tower Model"
            link={false}
          >
            <TextField source="modelName" />
          </ReferenceField>
        </ReferenceField>
        <BooleanField source="userIsTester" />
        <LinkToRelatedField
          source="totalDropoffs"
          to_path="/packages"
          filter_key="sender__email"
          filter_field="userEmail"
          label="Dropoffs"
        />
        <ReferenceField source="packageId" reference="packages" label="Package">
          <TextField source="id" sortable={false} />
        </ReferenceField>
        <EditButton></EditButton>
      </Datagrid>
    </List>
  );
}
