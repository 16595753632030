import * as React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useRecordContext } from "ra-core";

const ColoredTextField = ({ source, valueColor, valueIcon }) => {
  const record = useRecordContext();
  if (!record || record[source] === null) return null;

  const value = get(record, source);
  let sx = {};
  if (Object.prototype.hasOwnProperty.call(valueColor, value)) {
    sx["color"] = valueColor[value];
  }
  let icon = null;
  if (Object.prototype.hasOwnProperty.call(valueIcon, value)) {
    icon = valueIcon[value];
  }
  return (
    <Typography component="span" variant="body2" sx={sx}>
      {value != null && typeof value !== "string"
        ? JSON.stringify(value)
        : value}
      &nbsp;
      {icon}
    </Typography>
  );
};

ColoredTextField.propTypes = {
  source: PropTypes.string,
  valueColor: PropTypes.object,
  valueIcon: PropTypes.object,
};

export default ColoredTextField;
