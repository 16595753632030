import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  useNotify,
  BooleanInput,
} from "react-admin";
import { errorHandlingFactory } from "../../utils/errors";

export default function TowerEdit() {
  const notify = useNotify();
  const onError = errorHandlingFactory(notify);

  return (
    <Edit mutationOptions={{ onError }}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id"></TextInput>
        <SelectInput
          source="type"
          choices={[
            { id: "public", name: "Public" },
            { id: "test", name: "Test" },
          ]}
        />
        <TextInput source="imsiId" />
        <BooleanInput source="reported" />
        <TextInput source="internalNotes" multiline fullWidth />
      </SimpleForm>
    </Edit>
  );
}
