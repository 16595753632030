import * as React from "react";
import { useState, Fragment } from "react";

import { useMutation } from "react-query";
import {
  useDataProvider,
  Confirm,
  useNotify,
  useRecordContext,
  Button,
  useRefresh,
} from "react-admin";
import { getErrorMessage } from "../../utils/errors";

const ActivateSmartPickupButton = () => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider
      .addTowerToSmartPickups(record.id)
      .then(() => {
        notify("tower successfully added to Smart Pickups", { type: "info" });
        refresh();
      })
      .catch((err) => {
        const message = getErrorMessage(err);
        notify(`error adding Tower to Smart Pickups, ${message}`, {
          type: "error",
        });
      })
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        label="Add Tower to Smart Pickups"
        onClick={handleClick}
        color="inherit"
        size="medium"
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Add Tower to Smart Pickups"
        content={`Are you sure you want to add Tower ${record.id} to Smart Pickups?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default ActivateSmartPickupButton;
