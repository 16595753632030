import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  ShowButton,
  DateField,
  TextInput,
  BooleanInput,
  SelectInput,
  DateTimeInput,
} from "react-admin";

import DaysSinceField from "../custom_fields/daysSince";
import LinkToRelatedField from "../custom_fields/linkToRelated";
import { theme } from "../../theme";

const userFilters = [
  <TextInput label="Email" source="email" alwaysOn key={1} />,
  <BooleanInput source="email_validated" key={2} />,
  <SelectInput
    source="role"
    choices={[
      { id: "customer", name: "Customer" },
      { id: "driver", name: "Driver" },
      { id: "admin", name: "Admin" },
    ]}
    alwaysOn
    key={3}
  />,
  <BooleanInput source="isTester" key={4} />,
  <TextInput source="phoneNumber" alwaysOn key={5} />,
  <DateTimeInput source="last_dropoff_gt" label="Last Dropoff After" key={6} />,
  <DateTimeInput
    source="last_dropoff_lt"
    label="Last Dropoff Before"
    key={7}
  />,
];

const defaultSort = { field: "lastDropoff", order: "DESC" };

export default function UsersList() {
  const userRowStyle = (record) => ({
    backgroundColor: record.isTester
      ? theme.palette.specials.highlighRow
      : theme.backgroundColor,
  });

  return (
    <List filters={userFilters} perPage={25} sort={defaultSort}>
      <Datagrid rowStyle={userRowStyle}>
        <TextField source="id" />
        <TextField source="firstName" sortable={false} />
        <TextField source="lastName" sortable={false} />
        <TextField source="phoneNumber" sortable={false} />
        <TextField source="email" />
        <DaysSinceField source="lastDropoff" label="Last Dropoff" />
        <BooleanField source="emailValidated" sortable={false} />
        <BooleanField source="isActive" sortable={false} />
        <TextField source="role" sortable={false} />
        <BooleanField source="isTester" sortable={false} />
        <DateField source="createdAt" label="Signed Up" />
        <LinkToRelatedField
          source="totalDropoffs"
          to_path="/packages"
          filter_key="sender__email"
          filter_field="email"
          label="Dropoffs"
        />
        <ShowButton></ShowButton>
        <EditButton></EditButton>
      </Datagrid>
    </List>
  );
}
