import React from "react";
import { useEffect, useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  labelYellow: {
    color: "yellow",
  },
});

function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, inputs);
}

function Tag({ text, remove, disabled, className }) {
  const classes = useStyles();

  const handleOnRemove = (e) => {
    e.stopPropagation();
    remove(text);
  };

  return (
    <Chip
      label={text}
      onDelete={!disabled ? handleOnRemove : undefined}
      deleteIcon={<CloseIcon />}
      className={`${classes.labelYellow} ${className}`}
    />
  );
}

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

const defaultSeparators = ["Enter"];

export const TagsInput = (props) => {
  const {
    value = [],
    onChange,
    separators,
    beforeAddValidate,
    onExisting,
    disableBackspaceRemove,
    isEditOnRemove,
    onRemoved,
  } = props;

  const [tags, setTags] = useState(value || []);

  useDidUpdateEffect(() => {
    onChange && onChange(tags);
  }, [tags]);

  useDidUpdateEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(tags)) {
      setTags(value);
    }
  }, [value]);

  const handleOnKeyUp = (e) => {
    e.stopPropagation();

    const text = e.target.value;

    if (
      !text &&
      !disableBackspaceRemove &&
      tags.length &&
      e.key === "Backspace"
    ) {
      e.target.value = isEditOnRemove ? `${tags.at(-1)} ` : "";
      setTags([...tags.slice(0, -1)]);
    }

    if (text && (separators || defaultSeparators).includes(e.key)) {
      e.preventDefault();
      if (beforeAddValidate && !beforeAddValidate(text, tags)) return;

      if (tags.includes(text)) {
        onExisting && onExisting(text);
        return;
      }
      setTags([...tags, text]);
      e.target.value = "";
    }
  };

  const onTagRemove = (text) => {
    setTags(tags.filter((tag) => tag !== text));
    onRemoved && onRemoved(text);
  };

  return (
    <div aria-labelledby={props.name}>
      {tags.map((tag) => (
        <Tag
          key={tag}
          className={props.classNames?.tag}
          text={tag}
          remove={onTagRemove}
          disabled={props.disabled}
        />
      ))}

      <TextField
        className={props.classNames?.input}
        name={props.name}
        placeholder={props.placeHolder}
        onKeyDown={handleOnKeyUp}
        onBlur={props.onBlur}
        disabled={props.disabled}
        onKeyUp={props.onKeyUp}
        variant="outlined"
        size="small"
      />
    </div>
  );
};

TagsInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  separators: PropTypes.arrayOf(PropTypes.string),
  beforeAddValidate: PropTypes.func,
  onExisting: PropTypes.func,
  disableBackspaceRemove: PropTypes.bool,
  isEditOnRemove: PropTypes.bool,
  onRemoved: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  classNames: PropTypes.shape({
    tag: PropTypes.string,
    input: PropTypes.string,
  }),
};
