import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useRecordContext } from "react-admin";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

import { useInput } from "react-admin";

export const TimePickerCustom = (props) => {
  const record = useRecordContext();
  const initialPickUpTime = dayjs(record[props.source], "HH:mm:ss");
  const input = useInput(props);
  const name = input.field["name"];
  const onChange = input.field["onChange"];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={name}
        defaultValue={initialPickUpTime}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};

TimePickerCustom.propTypes = { source: PropTypes.string };
