import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export default function TowerCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="id"
          helperText="Must be a tower that is Own by UPS Dropbox"
        ></TextInput>
      </SimpleForm>
    </Create>
  );
}
