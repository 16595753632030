import React from "react";
import PropTypes from "prop-types";
import { Typography, Divider } from "@mui/material";
import { useRecordContext } from "react-admin";

const DaysSinceField = ({ source, className }) => {
  const record = useRecordContext();
  if (!record || record[source] === null) return null;

  const date = new Date(record[source]);
  const now = new Date();
  const timeDiff = now.getTime() - date.getTime();
  const minutesInMs = 1000 * 60;
  const hoursInMs = minutesInMs * 60;
  const daysInMs = hoursInMs * 24;

  const days = Math.floor(timeDiff / daysInMs);
  const hours = Math.floor((timeDiff % daysInMs) / hoursInMs);
  const minutes = Math.floor((timeDiff % hoursInMs) / minutesInMs);

  const options = {
    timeZone: "UTC",
  };
  const formattedDateTime = date.toLocaleString(undefined, options);

  return (
    <Typography component="span" variant="body2" className={className}>
      {days}d, {hours}h, {minutes}m
      <Divider />
      {`${formattedDateTime},${options.timeZone}`}
    </Typography>
  );
};

DaysSinceField.propTypes = {
  source: PropTypes.string,
  className: PropTypes.string,
};

export default DaysSinceField;
