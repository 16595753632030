import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import dayjs from "../../utils/daysJsSetup";

const DateTimeWithLocal = ({ source, className }) => {
  const record = useRecordContext();
  if (!record || record[source] === null) return null;
  const date = dayjs(record[source]);
  const towerLocalTZ = record["towerTimeZone"];

  return (
    <Typography component="span" variant="body2" className={className}>
      {towerLocalTZ !== null
        ? `${date
            .tz(towerLocalTZ)
            .format("M/D/YYYY, h:mm:ss A")},${towerLocalTZ}`
        : "Timezone not set for Location"}
    </Typography>
  );
};

DateTimeWithLocal.propTypes = {
  source: PropTypes.string,
  className: PropTypes.string,
};

export default DateTimeWithLocal;
