import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SelectInput,
  useRecordContext,
  NumberField,
  TextInput,
  ReferenceField,
  BooleanField,
  ShowButton,
} from "react-admin";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

import DateRangeFilter from "../custom_fields/datetimeFilter";
import DaysSinceField from "../custom_fields/daysSince";
import InternalNotesModalField from "../custom_fields/internalNotesModalField";

const LinkToRelatedLockers = () => {
  const record = useRecordContext();
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/lockers",
        search: `filter=${JSON.stringify({ tower_id: record.id })}`,
      }}
    >
      Lockers
    </Button>
  ) : null;
};

const towerFilters = [
  <TextInput source="id" alwaysOn key={1} />,
  <TextInput source="modelName" alwaysOn key={2} />,
  <SelectInput
    source="type"
    choices={[
      { id: "public", name: "Public" },
      { id: "test", name: "Test" },
    ]}
    alwaysOn
    key={3}
  />,
  <DateRangeFilter source="packages_from" label="Packages From" key={4} />,
];

export default function TowersList() {
  return (
    <List filters={towerFilters} perPage={25}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="modelName" />
        <TextField source="imsiId" />
        <BooleanField source="reported" />
        <ReferenceField source="locationId" reference="locations">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <NumberField source="successfulDropoffs" sortable={false} />
        <NumberField source="successfulPackagesDropoffs" sortable={false} />
        <NumberField
          source="totalDropoffsPending"
          sortable={false}
          label="Dropoffs Pending"
        />
        <DaysSinceField source="lastDropoff" />
        <LinkToRelatedLockers />
        <InternalNotesModalField source="internalNotes" />
        <ShowButton />
        <EditButton></EditButton>
      </Datagrid>
    </List>
  );
}
