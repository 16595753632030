import React from "react";
import {
  Show,
  TextField,
  ReferenceField,
  NumberField,
  SimpleShowLayout,
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import { theme } from "../../theme";
import DateTimeWithLocal from "../custom_fields/dateTimeWithLocal";

export default function PackageReportShow() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="towerId" reference="towers">
          <TextField source="name" />
        </ReferenceField>
        <DateTimeWithLocal source="fromDt" />
        <DateTimeWithLocal source="toDt" />
        <NumberField source="lockerDropoffCount" />
        <NumberField source="letterDropoffCount" />
        <JsonField
          source="firstDropoff"
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            name: "first Dropoff",
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: false,
            theme: "google",
            style: { backgroundColor: theme.palette.secondary.main },
          }}
        />
        <JsonField
          source="lastDropoff"
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            name: "last Dropoff",
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: false,
            theme: "google",
            style: { backgroundColor: theme.palette.secondary.main },
          }}
        />
        <JsonField
          source="pickup"
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            name: "Pickup",
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: false,
            theme: "google",
            style: { backgroundColor: theme.palette.secondary.main },
          }}
        />
        <JsonField
          source="eventStats"
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            name: "EventStats",
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: false,
            theme: "google",
            style: { backgroundColor: theme.palette.secondary.main },
          }}
        />
        <JsonField
          source="eventLines"
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            name: "EventLines",
            collapsed: true,
            enableClipboard: true,
            displayDataTypes: false,
            theme: "google",
            style: { backgroundColor: theme.palette.secondary.main },
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
}
