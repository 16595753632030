import React from "react";
import {
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  useNotify,
} from "react-admin";
import { errorHandlingFactory } from "../../utils/errors";

export default function CenterCreate() {
  const notify = useNotify();
  const onError = errorHandlingFactory(notify);

  return (
    <Create mutationOptions={{ onError }}>
      <SimpleForm>
        <TextInput source="name" />
        <NumberInput source="number" />
        <NumberInput source="internalNotes" multiline fullWidth />
      </SimpleForm>
    </Create>
  );
}
