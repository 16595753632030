import React from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "ra-core";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const LinkToRelatedField = ({ source, to_path, filter_key, filter_field }) => {
  const record = useRecordContext();
  if (!record || record[source] === null) return null;

  const value = record[source];
  let filter = {};
  filter[filter_key] = encodeURIComponent(record[filter_field]);

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: to_path,
        search: `filter=${JSON.stringify(filter)}`,
      }}
    >
      {value}
    </Button>
  );
};

LinkToRelatedField.propTypes = {
  source: PropTypes.string,
  to_path: PropTypes.string,
  filter_key: PropTypes.string,
  filter_field: PropTypes.string,
};

export default LinkToRelatedField;
