import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";
import InternalNotesModalField from "../custom_fields/internalNotesModalField";

export default function CenterList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <TextField source="number" />
        <InternalNotesModalField source="internalNotes" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
