import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  useNotify,
} from "react-admin";
import { errorHandlingFactory } from "../../utils/errors";

export default function PackageEdit() {
  const notify = useNotify();

  const onError = errorHandlingFactory(notify);

  return (
    <Edit mutationOptions={{ onError }}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id"></TextInput>
        <ReferenceInput label="sender" source="senderId" reference="users">
          <AutocompleteInput
            optionText="email"
            optionValue="id"
          ></AutocompleteInput>
        </ReferenceInput>
        <SelectInput
          source="status"
          label="Status"
          choices={[
            { id: "in_locker", name: "In Locker" },
            { id: "picked_up", name: "Picked Up" },
            { id: "dropoff_pending", name: "Dropoff Pending" },
            { id: "dropoff_canceled", name: "Dropoff Canceled" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
}
