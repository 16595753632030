import React from "react";
import { Admin, Resource } from "react-admin";
import { dataProvider } from "./components/DataProvider";

import UserIcon from "@mui/icons-material/People";
import Box from "@mui/icons-material/Book";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ApartmentIcon from "@mui/icons-material/Apartment";

import AuthProvider from "./components/AuthProvider";
import UsersList from "./components/users/UsersList";
import UserCreate from "./components/users/UserCreate";
import UserEdit from "./components/users/UserEdit";
import UserShow from "./components/users/UserShow";
import PackagesList from "./components/packages/PackagesList";
import PackageCreate from "./components/packages/PackageCreate";
import PackageEdit from "./components/packages/PackageEdit";
import PackageShow from "./components/packages/PackageShow";
import TowersList from "./components/towers/TowerList";
import TowerCreate from "./components/towers/TowerCreate";
import TowerEdit from "./components/towers/TowerEdit";
import LockerList from "./components/lockers/LockersList";
import LockerUpdate from "./components/lockers/LockerEdit";
import UserFeedbackList from "./components/user_feedback/UserFeedbackList";
import UserFeedbackEdit from "./components/user_feedback/UserFeedbackEdit";
import LoginPage from "./LoginPage";
import AppSettingsList from "./components/app_settings/AppSettingsList";
import AppSettingsEdit from "./components/app_settings/AppSettingsEdit";
import { theme } from "./theme";
import AppSettingsCreate from "./components/app_settings/AppSettingsCreate";
import LocationList from "./components/locations/LocationList";
import CenterList from "./components/centers/CenterList";
import CenterEdit from "./components/centers/CenterEdit";
import CenterCreate from "./components/centers/CenterCreate";
import LocationEdit from "./components/locations/LocationEdit";
import UpsReportsList from "./components/ups-reports/ReportsList";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PackageReportList from "./components/package_reports/PackageReportsList";
import PackageReportShow from "./components/package_reports/PakcageReportsDetail";
import TowerShow from "./components/towers/TowerShow";

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      theme={theme}
      loginPage={LoginPage}
      disableTelemetry
      requireAuth
    >
      <Resource
        name="users"
        list={UsersList}
        create={UserCreate}
        edit={UserEdit}
        show={UserShow}
        icon={UserIcon}
      />
      <Resource
        name="packages"
        list={PackagesList}
        create={PackageCreate}
        edit={PackageEdit}
        show={PackageShow}
        icon={Box}
      />
      <Resource
        name="towers"
        list={TowersList}
        icon={ViewHeadlineIcon}
        create={TowerCreate}
        edit={TowerEdit}
        show={TowerShow}
      />
      <Resource
        name="locations"
        list={LocationList}
        edit={LocationEdit}
        icon={LocationOnIcon}
      />
      <Resource
        name="centers"
        list={CenterList}
        edit={CenterEdit}
        create={CenterCreate}
        icon={ApartmentIcon}
      />
      <Resource
        name="lockers"
        list={LockerList}
        edit={LockerUpdate}
        icon={ViewQuiltIcon}
      />
      <Resource
        name="user-feedback"
        list={UserFeedbackList}
        edit={UserFeedbackEdit}
        icon={FeedbackIcon}
      />
      <Resource
        name="app-settings"
        list={AppSettingsList}
        edit={AppSettingsEdit}
        create={AppSettingsCreate}
        icon={AddToHomeScreenIcon}
      />
      <Resource
        name="ups-reports"
        list={UpsReportsList}
        icon={LocalShippingIcon}
      />
      <Resource
        name="package-reports"
        list={PackageReportList}
        show={PackageReportShow}
        // icon={TransferWithinAStationIcon}
      />
    </Admin>
  );
}

export default App;
