import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  PasswordInput,
  useNotify,
} from "react-admin";
import { errorHandlingFactory } from "../../utils/errors";

export default function UserEdit() {
  const notify = useNotify();
  const onError = errorHandlingFactory(notify);

  return (
    <Edit mutationOptions={{ onError }}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id"></TextInput>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="email" />
        <BooleanInput source="emailValidated" />
        <BooleanInput source="isActive" />
        <BooleanInput source="isTester" />
        <PasswordInput source="password" />
        <SelectInput
          source="role"
          choices={[
            { id: "customer", name: "Customer" },
            { id: "driver", name: "Driver" },
            { id: "admin", name: "Admin" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
}
