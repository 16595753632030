import React from "react";
import { List, Datagrid, TextField, NumberField, DateField } from "react-admin";

export default function UpsReportsList() {
  return (
    <List perPage={25}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="createdAt" />
        <DateField source="weekEnd" />
        <TextField source="pickupDt" />
        <NumberField source="center" />
        <TextField source="code7l" />
        <NumberField source="stopCompleteTime" />
        <TextField source="dispatchLoopPosition" />
        <TextField source="pkgServiceProvider" />
        <NumberField source="pickupPkgCount" />
        <TextField source="routeDriver" />
      </Datagrid>
    </List>
  );
}
