import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import dayjs from "../../utils/daysJsSetup";

const packageTransactionsExporter = (packages) => {
  const packagesForExport = packages.map((pack) => {
    const { towerTimeZone, ...packageForExport } = pack;
    let dropoffTimeLocalTZ = null;
    if (towerTimeZone !== null) {
      const date = dayjs(pack.dropoffTime);
      dropoffTimeLocalTZ = `${date
        .tz(towerTimeZone)
        .format("YYYY-M-D,h:mm:ss A")},${towerTimeZone}`;
    }
    packageForExport.dropoffTimeLocalTZ = dropoffTimeLocalTZ;
    return packageForExport;
  });
  jsonExport(
    packagesForExport,
    {
      headers: [
        "id",
        "senderId",
        "senderIsTester",
        "status",
        "statusId",
        "towerId",
        "lockerId",
        "dropoffTime",
        "dropoffTimeLocalTZ",
        "pickedupAt",
        "pickedupBy",
        "lockerName",
        "lockerType",
        "city",
        "state",
        "npsScope",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "packages");
    }
  );
};

export default packageTransactionsExporter;
