import React from "react";
import { Login, LoginForm } from "react-admin";
import { withStyles } from "@mui/styles";

const styles = {
  main: { background: "#333" },
};

const CustomLoginForm = withStyles({
  button: { background: "#F15922" },
})(LoginForm);

const CustomLoginPage = (props) => (
  <Login loginForm={<CustomLoginForm />} {...props} />
);

export default withStyles(styles)(CustomLoginPage);
