import React from "react";
import { Create, SimpleForm, NumberInput } from "react-admin";

export default function PackageCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput source="sender_id"></NumberInput>
        <NumberInput source="status_id"></NumberInput>
      </SimpleForm>
    </Create>
  );
}
