const AuthProvider = {
  login: ({ username, password }) => {
    var fromBody = [];
    var encodedUsername = encodeURIComponent("username");
    var encodedValueUsername = encodeURIComponent(username);
    fromBody.push(encodedUsername + "=" + encodedValueUsername);
    var encodedPassword = encodeURIComponent("password");
    var encodedValuePassword = encodeURIComponent(password);
    fromBody.push(encodedPassword + "=" + encodedValuePassword);

    fromBody = fromBody.join("&");

    const request = new Request(`${window.ENV.API_URL}/login/access-token`, {
      method: "POST",
      body: fromBody,
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem("auth", JSON.stringify(auth));
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("auth")
      ? Promise.resolve()
      : Promise.reject({ message: "login.required" }), // react-admin passes the error message to the translation layer
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve("/login");
  },
  getPermissions: () => {
    const role = "admin";
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default AuthProvider;
