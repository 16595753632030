import React from "react";
import { useState } from "react";
import {
  Title,
  useGetList,
  Datagrid,
  TextField,
  ListContextProvider,
  Pagination,
  ReferenceField,
  ExportButton,
  NumberField,
} from "react-admin";
import PropTypes from "prop-types";

import { Card } from "@mui/material";
import DaysSinceField from "../custom_fields/daysSince";
import exporterFactory from "./exporter";

const UserTransactionsList2 = ({ userId }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const sort = { field: "id", order: "DESC" };
  const filter = { sender_id: userId };
  const exporter = exporterFactory(userId);
  const { data, total, isLoading } = useGetList("packages", {
    pagination: { page, perPage },
    filter,
    sort,
  });
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <ListContextProvider
      value={{ data, total, page, setPerPage, perPage, setPage, sort }}
    >
      <Title title="User transactions" />
      <ExportButton
        exporter={exporter}
        filterValues={filter}
        resource="packages/"
      />
      <Card>
        <Datagrid data={data} sort={sort}>
          <TextField source="id" />
          <ReferenceField reference="users" source="senderId">
            <TextField source="email" />
          </ReferenceField>
          <TextField source="status" />
          <TextField source="towerId" />
          <TextField source="lockerId" />
          <TextField source="lockerName" />
          <TextField source="city" />
          <TextField source="state" />
          <DaysSinceField source="dropoffTime" />
          <DaysSinceField source="pickedupAt" />
          <NumberField source="npsScore" />
        </Datagrid>
      </Card>
      <Pagination />
    </ListContextProvider>
  );
};

UserTransactionsList2.propTypes = {
  userId: PropTypes.number,
};

export default UserTransactionsList2;
