import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
} from "react-admin";

import { JsonField } from "react-admin-json-view";
import { theme } from "../../theme";

export default function AppSettingsList() {
  return (
    <List perPage={10}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="type" sortable={false} />
        <TextField source="app" sortable={false} />
        <BooleanField source="default" sortable={false} />
        <JsonField
          source="settings"
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            // Props passed to react-json-view
            name: "Settings",
            collapsed: false,
            enableClipboard: true,
            displayDataTypes: false,
            theme: "google",
            style: { backgroundColor: theme.palette.secondary.main },
          }}
        />
        <EditButton></EditButton>
      </Datagrid>
    </List>
  );
}
