import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
} from "react-admin";

export default function UserCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="first_name"></TextInput>
        <TextInput source="last_name"></TextInput>
        <TextInput source="email" />
        <BooleanInput source="email_validated"></BooleanInput>
        <BooleanInput source="is_active"></BooleanInput>
        <SelectInput
          source="role"
          choices={[
            { id: "customer", name: "Customer" },
            { id: "driver", name: "Driver" },
            { id: "admin", name: "Admin" },
          ]}
        />
        <TextInput source="password"></TextInput>
      </SimpleForm>
    </Create>
  );
}
