import React from "react";
import { Show, SimpleShowLayout, TextField, DateField } from "react-admin";

export default function PackageShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="status" />
        <TextField source="lockerId" />
        <TextField source="lockerType" />
        <TextField source="lockerName" />
        <TextField source="sender" />
        <DateField source="dropoffTime" />
        <DateField source="pickupTime" />
      </SimpleShowLayout>
    </Show>
  );
}
