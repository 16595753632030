import React from "react";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: 300,
    textAlign: "left",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "20px",
    "& li": {
      marginBottom: "8px",
    },
  },
}));

const AccessModeInfoTooltip = ({ content }) => {
  const classes = useStyles();

  const icon = (
    <InfoOutlinedIcon
      color="primary"
      style={{ marginLeft: 10, cursor: "pointer" }}
    />
  );

  const tooltip = (
    <div className={classes.tooltip}>
      <ul className={classes.list}>
        <li>
          <strong>Public:</strong> {content.public}
        </li>
        <li>
          <strong>Exclusive:</strong> {content.exclusive}
        </li>
      </ul>
    </div>
  );

  return (
    <Tooltip title={tooltip} placement="right-end">
      {icon}
    </Tooltip>
  );
};

export const AccessModeHeader = () => {
  const fieldName = "Access Mode";

  const style = {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
  };

  const statusContent = {
    public:
      "This locker is available for use by all harbor network service providers.",
    exclusive: "This locker is available for UPS exclusive use.",
  };

  return (
    <span style={style}>
      <span>{fieldName}</span>
      <AccessModeInfoTooltip content={statusContent} />
    </span>
  );
};

AccessModeInfoTooltip.propTypes = {
  content: PropTypes.shape({
    public: PropTypes.string,
    exclusive: PropTypes.string,
  }),
};

export default AccessModeHeader;
